:root {
  --dialog-border-radius: 16px;
}

* {
  box-sizing: border-box;
}

.app {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
}

.tlf-logo {
  position: absolute;
  left: 48px;
  top: 48px;
}

.title {
  font-size: 48px;
  line-height: 54px;
  font-weight: 900;
}

.container {
  max-width: 1204px;
  padding-left: 24px;
  padding-right: 24px;
}

.dialog {
  border-radius: var(--dialog-border-radius);
  width: 980px;
}

.dialog::before {
  border-radius: var(--dialog-border-radius);
}

.dialog > div {
  border-radius: var(--dialog-border-radius);
}

.dialog-left,
.dialog-right {
  height: 540px;
}

.dialog-left {
  background-size: cover;
}

.dialog-left-title {
  color: #fff;
  font-size: 60px;
  font-weight: 900;
  line-height: 60px;
  text-transform: uppercase;
}

.dialog-right-title {
  font-size: 36px;
  font-weight: 900;
  line-height: 36px;
}

.dialog-close-button {
  position: absolute;
  right: 30px;
  top: 30px;
}

[data-teamleader-ui='overlay'] {
  background-color: rgba(87, 211, 210, 0.84) !important;
}
