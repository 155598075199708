@import '@teamleader/ui-colors';
@import '@teamleader/ui-typography';

:root {
  --wheel-font: var(--font-family-inter);
  --wheel-size: 640px;
  --wheel-slice-spacing: 50px;
  --wheel-border-size: 5px;
  --wheel-color: var(--color-mint);
  --neutral-color: var(--color-neutral-lightest);
  --text-color: var(--color-teal-darkest);
  --PI: 3.14159265358979;
  --nb-item: 0;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 5;
  --spinning-duration: 4s;
  --reset-duration: 0s;
}

.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  padding: 3px;
  background-color: var(--neutral-color);
  border: solid var(--wheel-color) 3px;
  border-radius: 50%;
  user-select: none;
}

.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  border: solid var(--wheel-color) var(--wheel-border-size);
  background-color: var(--wheel-color);
  transition: transform var(--reset-duration);
  transform: rotate(0deg);
}

.spinning .wheel {
  transition: transform var(--spinning-duration);
  transform: rotate(
    calc(
      var(--nb-turn) * 360deg +
        (-360deg * var(--selected-item) / var(--nb-item, 1))
    )
  );
}

.wheel::after {
  display: block;
  position: absolute;
  content: '';
  background-color: var(--neutral-color);
  width: 25px;
  height: 25px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;

  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%)
    rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

  color: var(--text-color);
  text-align: right;
  padding: 0 25px 0 100px;
  font-family: var(--wheel-font);
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
}

.wheel-item:before {
  content: ' ';
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;
  --slice-max-width: calc(
    var(--PI) * var(--wheel-size) + var(--wheel-size) / 2
  );
  --slice-width: calc(
    (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
  );
  border: solid transparent calc(var(--slice-width) / 2);
  border-left: solid transparent 0;
  border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
}

.wheel-latch {
  position: absolute;
  top: calc(50% - 40px);
  right: -70px;
  transform-origin: 40px 40px;
  transform: rotate(90deg);
  height: 108px;
  width: 80px;
}

.spinning .wheel-latch {
  animation-name: wobble;
  animation-duration: var(--spinning-duration);
}

@keyframes wobble {
  0% {
    transform: rotate(100deg);
  }
  2% {
    transform: rotate(80deg);
  }
  4% {
    transform: rotate(100deg);
  }
  6% {
    transform: rotate(80deg);
  }
  8% {
    transform: rotate(100deg);
  }
  10% {
    transform: rotate(80deg);
  }
  12% {
    transform: rotate(100deg);
  }
  14% {
    transform: rotate(80deg);
  }
  16% {
    transform: rotate(100deg);
  }
  18% {
    transform: rotate(80deg);
  }
  20% {
    transform: rotate(100deg);
  }
  22% {
    transform: rotate(80deg);
  }
  24% {
    transform: rotate(100deg);
  }
  27% {
    transform: rotate(80deg);
  }
  30% {
    transform: rotate(100deg);
  }
  33% {
    transform: rotate(80deg);
  }
  36% {
    transform: rotate(100deg);
  }
  40% {
    transform: rotate(80deg);
  }
  44% {
    transform: rotate(100deg);
  }
  48% {
    transform: rotate(80deg);
  }
  53% {
    transform: rotate(100deg);
  }
  58% {
    transform: rotate(80deg);
  }
  63% {
    transform: rotate(100deg);
  }
  68% {
    transform: rotate(80deg);
  }
  74% {
    transform: rotate(100deg);
  }
  82% {
    transform: rotate(80deg);
  }
  90% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
